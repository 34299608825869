var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('button',{class:[
        'button',
        `button--${ _vm.color }`,
        {
            'button--smaller': _vm.smaller,
        }
    ],attrs:{"type":_vm.type,"disabled":_vm.disabled,"title":_vm.title},on:{"click":function($event){return _vm.$emit('click', $event)}}},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }