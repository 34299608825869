var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"labelsPanel",staticClass:"panel",class:{
        'show': _vm.show,
    },style:(_vm.panelPosition)},[_c('div',{staticClass:"panel__head"},[_c('strong',{staticClass:"panel__label label"},[_vm._v("Labels")]),_c('i',{staticClass:"panel__close",on:{"click":function($event){return _vm.close()}}})]),_c('div',{staticClass:"panel__content"},[_c('ul',{staticClass:"panel__labels"},_vm._l((_vm.labels),function(label){return _c('li',{key:label.id,staticClass:"labels__label",class:{
                    'labels__label--added': _vm.labelIsAssigned(label),
                },style:({
                    'background-color': label.hex,
                }),on:{"click":function($event){return _vm.toggleLabel(label)}}},[_vm._v(" "+_vm._s(label.text)+" "),_c('i',{staticClass:"label__check"})])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }