"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mutations = void 0;
exports.mutations = {
    // Data of the loaded organization
    organization(state, organization) {
        state.organization = organization;
    },
    // Data of the logged in user
    user(state, user) {
        state.user = user;
    },
    // Update active region of the user
    region(state, region) {
        if (!state.user) {
            throw new Error('Unable to set `active_region` when no user is stored');
        }
        state.user.active_region = region;
    },
    // Loading states, each module also holds it's own loading state
    loading_application(state, loading) {
        state.loading.application = loading;
    },
    loading_filter(state, loading) {
        state.loading.filter = loading;
    },
    // Global notification (should be improved)
    notification(state, options) {
        // Reset the notification props when false is provided as options
        if (options === false) {
            state.notification = {
                show: false,
                type: 'success',
                message: '',
            };
        }
        else {
            state.notification = {
                show: true,
                // @ts-ignore
                type: options.type,
                // @ts-ignore
                message: options.message,
            };
        }
    },
    /**
     *  Used to toggle the state of the extended filter.
     */
    toggle_extended_filter(state) {
        state.filter.extended = !state.filter.extended;
    },
    /**
     *  Used to toggle the archived state.
     */
    toggle_archived(state) {
        state.filter.archived = !state.filter.archived;
    },
    generalData(state, options) {
        switch (options.type) {
            case 'buildings': {
                state.data.buildings = options.data;
                break;
            }
            case 'mechanics': {
                state.data.mechanics = options.data;
                break;
            }
            case 'users': {
                state.data.users = options.data;
                break;
            }
            default: {
                throw new Error(`Trying to store unknown general data "${options.type}"`);
            }
        }
    },
};
