var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"coldtec-management-app__orders"},[_c('orders-dropzone',{attrs:{"disabled":!_vm.userCan('order.add') || _vm.details.order !== null},on:{"drop":_vm.newOrder}}),_c('order-details',{attrs:{"order":_vm.details.order,"document-preview":_vm.details.document_preview},on:{"document-preview-closed":_vm.documentPreviewClosed,"update-order":_vm.updateOrder,"close-order":_vm.closeOrder}}),_c('section',{ref:"content",staticClass:"orders__content",class:{
            'orders__content--notification': _vm.$store.getters.notification.show,
            'orders__content--frozen': _vm.freeze,
        }},[_c('div',{class:[
                'orders__lists',
                {
                    'orders__lists--including-archived': _vm.$store.getters.filter.archived,
                }
            ]},[_c('order-list',{ref:"list--new",attrs:{"type":"new","title":"Nieuw","orders":_vm.orders.new,"topOption":_vm.newTopOption,"loading":_vm.isLoading || _vm.moving},on:{"open-order":_vm.openOrder,"update-order":_vm.updateOrder}}),_c('order-list',{ref:"list--in_progress",attrs:{"type":"in_progress","title":"In behandeling","orders":_vm.orders.in_progress,"loading":_vm.isLoading || _vm.moving},on:{"open-order":_vm.openOrder,"update-order":_vm.updateOrder}}),_c('order-list',{ref:"list--waiting",attrs:{"type":"waiting","title":"In afwachting","orders":_vm.orders.waiting,"loading":_vm.isLoading || _vm.moving},on:{"open-order":_vm.openOrder,"update-order":_vm.updateOrder}}),_c('order-list',{ref:"list--control",attrs:{"type":"control","title":"Controle","orders":_vm.orders.control,"loading":_vm.isLoading || _vm.moving},on:{"open-order":_vm.openOrder,"update-order":_vm.updateOrder}}),_c('order-list',{ref:"list--done",attrs:{"type":"done","title":"Afgerond","orders":_vm.orders.done,"loading":_vm.isLoading || _vm.moving},on:{"open-order":_vm.openOrder,"update-order":_vm.updateOrder}}),(_vm.$store.getters.filter.archived)?_c('order-list',{ref:"list--archived",attrs:{"type":"archived","title":"Gearchiveerd","orders":_vm.orders.archived,"loading":_vm.isLoading || _vm.moving},on:{"open-order":_vm.openOrder,"update-order":_vm.updateOrder}}):_vm._e()],1)]),(_vm.$refs.content)?_c('orders-scrollbar',{ref:"scrollbar",attrs:{"content":_vm.$refs.content}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }