var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('header',[_c('section',{staticClass:"coldtec-management-app__header"},[_c('div',{staticClass:"header__content"},[_c('div',{staticClass:"header__left"},[_c('logo',{staticClass:"header__logo",attrs:{"smaller":'small'}}),_c('div',{staticClass:"header__regions-modules"},[(_vm.regions.length > 1)?_c('div',{staticClass:"header__regions"},[_c('c-input',{staticClass:"regions__select",attrs:{"type":"select","name":"region","placeholder":"Selecteer regio","options":_vm.regions,"disabled":_vm.$store.getters.loading.filter || _vm.settingRegion},scopedSlots:_vm._u([{key:"default",fn:function(region){return [_vm._v(" "+_vm._s(region.option.name)+" ")]}}],null,false,394889939),model:{value:(_vm.$store.getters.user.active_region),callback:function ($$v) {_vm.$set(_vm.$store.getters.user, "active_region", $$v)},expression:"$store.getters.user.active_region"}})],1):_vm._e(),(_vm.regionSupportsCupboards)?_c('ul',{staticClass:"header__modules"},[_c('li',{staticClass:"modules__module modules__module--orders",class:{
                                'modules__module--active': _vm.showingOrders,
                            }},[_c('c-button',{attrs:{"color":_vm.showingOrders ? 'blue' : 'transparent'},on:{"click":function($event){return _vm.navigate('orders')}}},[_vm._v(" Opdrachten ")])],1),_c('li',{staticClass:"modules__module modules__module--cupboards",class:{
                                'modules__module--active': _vm.showingCupboards,
                            }},[_c('c-button',{attrs:{"color":_vm.showingCupboards ? 'blue' : 'transparent'},on:{"click":function($event){return _vm.navigate('cupboards')}}},[_vm._v(" Kasten ")])],1)]):_vm._e()])],1),_c('div',{staticClass:"header__right"},[_c('div',{staticClass:"header__filter"},[_c('div',{staticClass:"header__search",class:{
                            'header__search--activated': _vm.searchActivated,
                            'header__search--only': !_vm.$store.getters.buildings.length,
                            'header__search--disabled': _vm.$store.getters.filter.extended,
                        }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$store.getters.filter.settings[_vm.moduleName].number),expression:"$store.getters.filter.settings[moduleName].number"}],ref:"searchfield",staticClass:"search__field",attrs:{"type":"text","placeholder":_vm.searchPlaceholder,"disabled":_vm.$store.getters.loading.filter || _vm.$store.getters.filter.extended},domProps:{"value":(_vm.$store.getters.filter.settings[_vm.moduleName].number)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$store.getters.filter.settings[_vm.moduleName], "number", $event.target.value)}}}),_c('i',{staticClass:"search__reset",on:{"click":function($event){return _vm.reset('search')}}})]),(_vm.$store.getters.buildings.length)?_c('div',{staticClass:"header__filter-btn",class:{
                            'header__filter-btn--open': _vm.$store.getters.filter.extended,
                            'header__filter-btn--applied': _vm.filterActivated,
                        },on:{"click":function($event){return _vm.$store.commit('toggle_extended_filter')}}},[_c('i',{staticClass:"filter-btn",attrs:{"title":_vm.extendedFilterBtnTitle}})]):_vm._e()]),_c('div',{staticClass:"header__options-user"},[(_vm.showingOrders)?_c('ul',{staticClass:"header__options"},[_c('li',{staticClass:"header__option"},[_c('c-button',{class:[
                                    'header__archived',
                                    {
                                        'header__archived--active': _vm.$store.getters.filter.archived,
                                    }
                                ],attrs:{"type":"button","color":_vm.$store.getters.filter.archived ? 'green' : 'transparent',"title":_vm.$store.getters.filter.archived ? 'Verberg gearchiveerde opdrachten' : 'Toon gearchiveerde opdrachten'},on:{"click":function($event){return _vm.$store.commit('toggle_archived')}}},[_c('i',{staticClass:"archived__icon"})])],1)]):_vm._e(),_c('ul',{staticClass:"header__user"},[_c('li',{staticClass:"header__username"},[_c('i',{staticClass:"user__icon user__icon--user"}),_c('span',{staticClass:"user__text"},[_vm._v(" Ingelogd als "),_c('strong',[_vm._v(_vm._s(_vm.$store.getters.userName))])])]),_c('li',{staticClass:"header__signout",on:{"click":function($event){return _vm.$parent.logout()}}},[_c('i',{staticClass:"signout__icon signout__icon--sign-out"}),_c('span',{staticClass:"signout__text"},[_vm._v(" Uitloggen ")])])])])])])]),_c('section',{staticClass:"coldtec-management-app__extended-filter",class:{
            'coldtec-management-app__extended-filter--open': _vm.$store.getters.filter.extended,
        }},[_c('div',{staticClass:"extended-filter__content"},[_vm._m(0),_c('div',{staticClass:"extended-filter__column extended-filter__column--numbers"},[_c('c-input',{attrs:{"type":"number","name":"number","label":"Nummer","placeholder":_vm.searchPlaceholder,"disabled":_vm.$store.getters.loading.filter},model:{value:(_vm.$store.getters.filter.settings[_vm.moduleName].number),callback:function ($$v) {_vm.$set(_vm.$store.getters.filter.settings[_vm.moduleName], "number", $$v)},expression:"$store.getters.filter.settings[moduleName].number"}})],1),_c('div',{staticClass:"extended-filter__column extended-filter__column--building"},[_c('c-input',{attrs:{"type":"select","name":"building","label":"Gebouw","placeholder":"Filter op gebouw","options":_vm.$store.getters.buildings,"disabled":_vm.$store.getters.loading.filter || !_vm.$store.getters.buildings.length,"deselectable":true},scopedSlots:_vm._u([{key:"default",fn:function(building){return [_c('strong',[_vm._v(_vm._s(building.option.number))]),_vm._v(" - "+_vm._s(building.option.name)+" ")]}},{key:"deselect",fn:function(){return [_vm._v(" Niet filteren op gebouw ")]},proxy:true}]),model:{value:(_vm.$store.getters.filter.settings[_vm.moduleName].building),callback:function ($$v) {_vm.$set(_vm.$store.getters.filter.settings[_vm.moduleName], "building", $$v)},expression:"$store.getters.filter.settings[moduleName].building"}})],1),_c('div',{staticClass:"extended-filter__column extended-filter__column--reset"},[_c('c-button',{staticClass:"extended-filter__reset-btn",attrs:{"type":"button","color":"blue","title":"Klik om de filters te resetten","disabled":_vm.$store.getters.loading.filter || !_vm.filterActivated},on:{"click":function($event){return _vm.reset('filter')}}},[_vm._v(" Reset filter ")])],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"extended-filter__column extended-filter__column--heading"},[_c('h2',{staticClass:"extended-filter__heading"},[_vm._v(" Filteren ")])])
}]

export { render, staticRenderFns }