var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"coldtec-management-app__details-overlay",class:{
        'coldtec-management-app__details-overlay--open': _vm.open,
        'coldtec-management-app__details-overlay--archived': _vm.archived,
    }},[_c('div',{staticClass:"details-overlay__close",on:{"click":_vm.close}}),_c('div',{staticClass:"details-overlay__block"},[_c('div',{staticClass:"details-overlay__archived"}),_c('header',{staticClass:"details-overlay__header"},[_c('div',{staticClass:"header__left"},[_c('div',{staticClass:"header__label",style:({
                        'background-color': _vm.labelColor,
                    })},[_vm._v(" "+_vm._s(_vm.labelText)+" ")]),(_vm.subLabelText)?_c('div',{staticClass:"header__sublabel"},[_vm._v(" "+_vm._s(_vm.subLabelText)+" ")]):_vm._e(),_c('h1',{staticClass:"header__title"},[_vm._v(" "+_vm._s(_vm.heading)+" ")])]),_c('div',{staticClass:"header__right"},[(_vm.mayEdit && !_vm.editing)?_c('div',{staticClass:"header__edit",on:{"click":function($event){return _vm.$emit('edit', true)}}},[_c('i',{staticClass:"edit__icon"}),_c('span',{staticClass:"edit__text"},[_vm._v("Aanpassen")])]):_vm._e(),_c('div',{staticClass:"header__slot"},[_vm._t("header-right")],2)])]),_c('main',{ref:"wrapper",staticClass:"details-overlay__wrapper"},[(_vm.$slots.subheader)?_c('section',{staticClass:"details-overlay__subheader"},[_vm._t("subheader")],2):_vm._e(),(_vm.loading)?_c('loader'):_vm._e(),_c('section',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],staticClass:"details-overlay__content"},[_c('confirm-modal',{ref:"confirmModal"}),_vm._t("content")],2)],1),_c('footer',{staticClass:"details-overlay__footer"},[_c('section',{staticClass:"details-overlay__actions-wrapper"},[(_vm.notification.show)?_c('notification',{attrs:{"type":_vm.notification.type,"message":_vm.notification.message},on:{"click":function($event){return _vm.showNotification(false)}}}):_c('div',{staticClass:"details-overlay__actions"},[_c('div',{staticClass:"actions__default"},[_vm._t("actions")],2),(_vm.archivable)?_c('div',{staticClass:"actions__archive",on:{"click":function($event){return _vm.$emit('archive')}}},[_c('i',{class:[
                                'archive__icon',
                                {
                                    'archive__icon--archived': _vm.archived,
                                },
                            ],attrs:{"title":_vm.archived ? 'Uit archief halen' : 'Archiveren'}})]):_vm._e()])],1),(_vm.added || _vm.updated || _vm.archived)?_c('section',{staticClass:"details-overlay__details"},[(_vm.added)?_c('span',{staticClass:"detail"},[_vm._v(" Aangemaakt op "),_c('strong',[_vm._v(_vm._s(_vm.added.date))]),_vm._v(", door "),_c('a',{attrs:{"href":`mailto:${ _vm.added.by.email }`,"title":`Klik om ${ _vm.addedByName } een e-mail te sturen`}},[_vm._v(" "+_vm._s(_vm.addedByName)+" ")])]):_vm._e(),(_vm.updated)?_c('span',{staticClass:"detail"},[_vm._v(" Aangepast op "),_c('strong',[_vm._v(_vm._s(_vm.updated.date))]),_vm._v(", door "),_c('a',{attrs:{"href":`mailto:${ _vm.updated.by.email }`,"title":`Klik om ${ _vm.updatedByName } een e-mail te sturen`}},[_vm._v(" "+_vm._s(_vm.updatedByName)+" ")])]):_vm._e(),(_vm.archived)?_c('span',{staticClass:"detail"},[_vm._v(" Gearchiveerd op "),_c('strong',[_vm._v(_vm._s(_vm.archived.date))]),_vm._v(", door "),_c('a',{attrs:{"href":`mailto:${ _vm.archived.by.email }`,"title":`Klik om ${ _vm.archivedByName } een e-mail te sturen`}},[_vm._v(" "+_vm._s(_vm.archivedByName)+" ")])]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"details-overlay__overlay",class:{
            'details-overlay__overlay--full-on-smaller': _vm.fullOverlayOnSmaller,
        }},[_vm._t("overlay")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }