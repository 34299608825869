import { render, staticRenderFns } from "./labels.panel.vue?vue&type=template&id=ff0eee88&scoped=true"
import script from "./labels.panel.vue?vue&type=script&lang=ts"
export * from "./labels.panel.vue?vue&type=script&lang=ts"
import style0 from "./labels.panel.vue?vue&type=style&index=0&id=ff0eee88&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ff0eee88",
  null
  
)

export default component.exports